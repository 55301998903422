import { useCallback, useMemo, useState } from 'react';

// This hook is used to limit the number of products initially shown to a user
// on a product list page. It provides a function which can be called (when the
// user clicks Load More) to increase the number of products that are shown.

// Note: the actual data loading should occur in a separate hook (e.g.
// useProductListingQuery), this hook just limits the number that are shown.

import { NUMBER_OF_PRODUCTS_TO_SHOW } from 'commons/constants';

// Note: a separate totalProductCount argument is critical because the products
// prop may only contain the initial set of products instead of all products.
const useLoadMoreProducts = ({ products = [], totalProductCount }) => {
  const [numberOfProductsToShow, setNumberOfProductsToShow] = useState(
    NUMBER_OF_PRODUCTS_TO_SHOW
  );

  const [loadMoreClickCount, setLoadMoreClickCount] = useState(0);

  const loadMoreProducts = useCallback(() => {
    setNumberOfProductsToShow(
      prevNumberOfProductsToShow =>
        prevNumberOfProductsToShow + NUMBER_OF_PRODUCTS_TO_SHOW
    );
    setLoadMoreClickCount(prevCount => prevCount + 1);
  }, []);

  const loadAllProducts = useCallback(() => {
    setNumberOfProductsToShow(-1);
    setLoadMoreClickCount(-1);
  }, []);

  const resetNumberOfProductsToShow = useCallback(() => {
    setNumberOfProductsToShow(NUMBER_OF_PRODUCTS_TO_SHOW);
  }, []);

  const productsWithLoadMoreState = useMemo(() => {
    // If the number of products to show is -1, then show all products
    const areAllProductsShown = numberOfProductsToShow === -1;

    const productsToShow = areAllProductsShown
      ? products
      : products.slice(0, numberOfProductsToShow);

    const productsToPreload =
      !areAllProductsShown &&
      products.length > numberOfProductsToShow + NUMBER_OF_PRODUCTS_TO_SHOW
        ? products.slice(
            numberOfProductsToShow,
            numberOfProductsToShow + NUMBER_OF_PRODUCTS_TO_SHOW
          )
        : [];

    return {
      loadMoreClickCount,
      loadAllProducts,
      loadMoreProducts,
      productsToPreload,
      productsToShow,
      resetNumberOfProductsToShow,
      showLoadMoreButton:
        !areAllProductsShown &&
        totalProductCount > numberOfProductsToShow &&
        productsToShow.length > 0,
    };
  }, [
    loadMoreClickCount,
    loadAllProducts,
    loadMoreProducts,
    numberOfProductsToShow,
    products,
    resetNumberOfProductsToShow,
    totalProductCount,
  ]);

  return productsWithLoadMoreState;
};

export default useLoadMoreProducts;
