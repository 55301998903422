export const storeUrlSlugMapping = {
  '/showrooms/brooklyn/': {
    slug: 'brooklyn-store',
    showroom: {
      name: 'Brooklyn Showroom',
      hours: [
        'Mon: 11:00am - 7:00pm',
        'Tues: 11:00am - 7:00pm',
        'Wed: 11:00am - 7:00pm',
        'Thurs: 11:00am - 7:00pm',
        'Fri: 11:00am - 7:00pm',
        'Sat: 11:00am - 7:00pm',
        'Sun: 11:00am - 7:00pm',
      ],
      url: 'https://joybird.com/showrooms/brooklyn/',
      phone: '(718) 855-1600',
      calendly: 'https://calendly.com/joybird-brooklyn',
      location: '445 Albee Square W, Brooklyn, NY 11201',
      locationUrl:
        'https://www.google.com/maps/place/445+Albee+Square+W,+Brooklyn,+NY+11201/data=!4m2!3m1!1s0x89c25a4ca888ba81:0xf31298c2df916c7b?sa=X&amp;ved=0ahUKEwjoy9X2odHbAhXNuFkKHV-wAJoQ8gEIJjAA',
    },
  },
  '/showrooms/dc/': {
    slug: 'dc-store',
    showroom: {
      name: 'DC Showroom',
      hours: [
        'Mon: 11:00am - 7:00pm',
        'Tues: 11:00am - 7:00pm',
        'Wed: 11:00am - 7:00pm',
        'Thurs: 11:00am - 7:00pm',
        'Fri: 11:00am - 7:00pm',
        'Sat: 11:00am - 7:00pm',
        'Sun: 12:00pm - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/dc/',
      phone: '(202) 975-7014',
      calendly:
        'https://calendly.com/joybird-logan-circle-dc/45-minute-virtual-stylist-appointment',
      location: '1328 14th Street NW, Washington, DC 20005',
      locationUrl:
        'https://www.google.com/maps/place/1328+14th+St+NW,+Washington,+DC+20005/@38.9082106,-77.0345832,17z/data=!3m1!4b1!4m5!3m4!1s0x89b7b7ea8964f82b:0xff625f3026e2c152!8m2!3d38.9082064!4d-77.0323945',
    },
  },
  '/showrooms/chicago/': {
    slug: 'chicago-store',
    showroom: {
      name: 'Chicago Showroom',
      hours: [
        'Mon: 10:00am - 6:00pm',
        'Tues: 10:00am - 6:00pm',
        'Wed: 10:00am - 6:00pm',
        'Thurs: 10:00am - 6:00pm',
        'Fri: 10:00am - 6:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 12:00pm - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/chicago/',
      phone: '(312) 788-6863',
      calendly: 'https://calendly.com/joybird-chicago-1',
      location: '755 West North Avenue, Chicago, IL 60610',
      locationUrl:
        'https://www.google.com/maps/place/755+W+North+Ave,+Chicago,+IL+60610/@41.910597,-87.6500493,17z/data=!3m1!4b1!4m5!3m4!1s0x880fd33b55fddfb3:0x290203d61d6b4fa4!8m2!3d41.910597!4d-87.6478606',
    },
  },
  '/showrooms/los-angeles/': {
    slug: 'la-store',
    showroom: {
      name: 'Los Angeles Showroom',
      hours: [
        'Mon: 10:00am - 6:00pm',
        'Tues: 10:00am - 6:00pm',
        'Wed: 10:00am - 6:00pm',
        'Thurs: 10:00am - 6:00pm',
        'Fri: 10:00am - 6:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 12:00pm - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/los-angeles/',
      phone: '(323) 483-7006',
      calendly: 'https://calendly.com/joybird-los-angeles',
      location: '8335 Melrose Ave, Los Angeles, CA 90069',
      locationUrl:
        'https://www.google.com/maps/place/8335+Melrose+Ave,+Los+Angeles,+CA+90069/data=!4m2!3m1!1s0x80c2beb645b5ecab:0xaa4eb7ca952f5b01?sa=X&ved=2ahUKEwiZvIX554jyAhX8B50JHeJgD_kQ8gF6BAgKEAE',
    },
  },
  '/showrooms/austin/': {
    slug: 'austin-store',
    showroom: {
      name: 'Austin Showroom',
      hours: [
        'Mon: 10:00am - 6:00pm',
        'Tues: 10:00am - 6:00pm',
        'Wed: 10:00am - 6:00pm',
        'Thurs: 10:00am - 6:00pm',
        'Fri: 10:00am - 6:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 12:00pm - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/austin/',
      phone: '(737) 999-7532',
      calendly: 'https://calendly.com/joybird-austin',
      location: '3211 Palm Way #162, Austin, TX 78758',
      locationUrl: 'https://goo.gl/maps/o2H36Qi9APnvCgnJ7',
    },
  },
  '/showrooms/seattle/': {
    slug: 'seattle-store',
    showroom: {
      name: 'Seattle Showroom',
      hours: [
        'Mon: 11:00am - 7:00pm',
        'Tues: 11:00am - 7:00pm',
        'Wed: 11:00am - 7:00pm',
        'Thurs: 11:00am - 7:00pm',
        'Fri: 11:00am - 7:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 11:00am - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/seattle/',
      phone: '(206) 629-3759',
      calendly: '',
      location: '1520 10th Ave, Seattle, WA 98122',
      locationUrl: 'https://goo.gl/maps/PmygtjNfjbBz5bWY6',
    },
  },
  '/showrooms/manhattan/': {
    slug: 'manhattan-store',
    showroom: {
      name: 'Manhattan Showroom',
      hours: [
        'Mon: 10:00am - 6:00pm',
        'Tues: 10:00am - 6:00pm',
        'Wed: 10:00am - 6:00pm',
        'Thurs: 10:00am - 6:00pm',
        'Fri: 10:00am - 6:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 11:00am - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/manhattan/',
      phone: '(516) 254-7627',
      calendly: 'https://calendly.com/joybirdsoho',
      location: '83 Wooster St, New York, NY 10012',
      locationUrl: 'https://goo.gl/maps/KD8YsBE1nbgoMnho7',
    },
  },
  '/showrooms/downtown-la/': {
    slug: 'dtla-store',
    showroom: {
      name: 'Downtown Los Angeles Showroom',
      hours: [
        'Mon: 11:00am - 7:00pm',
        'Tues: 11:00am - 7:00pm',
        'Wed: 11:00am - 7:00pm',
        'Thurs: 11:00am - 7:00pm',
        'Fri: 11:00am - 7:00pm',
        'Sat: 11:00am - 7:00pm',
        'Sun: 11:00am - 7:00pm',
      ],
      url: 'https://joybird.com/showrooms/dtla/',
      phone: '',
      calendly: '',
      location: '767 S Alameda St, Suite 152 Los Angeles, CA 90021',
      locationUrl: 'https://goo.gl/maps/TGAhLoZmkmGvwHY6A',
    },
  },
  '/showrooms/philadelphia/': {
    slug: 'philadelphia-store',
    showroom: {
      name: 'Philadelphia Showroom',
      hours: [
        'Mon: 11:00am - 7:00pm',
        'Tues: 11:00am - 7:00pm',
        'Wed: 11:00am - 7:00pm',
        'Thurs: 11:00am - 7:00pm',
        'Fri: 11:00am - 7:00pm',
        'Sat: 11:00am - 7:00pm',
        'Sun: 11:00am - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/philadelphia/',
      phone: '',
      calendly: '',
      location: '1507 Walnut St., Philadelphia, PA 19102',
      locationUrl: 'https://goo.gl/maps/G26np4rUu3aeLGoc9',
    },
  },
  '/showrooms/denver/': {
    slug: 'denver-showroom',
    showroom: {
      name: 'Denver Showroom',
      hours: [
        'Mon: 10:00am - 6:00pm',
        'Tues: 10:00am - 6:00pm',
        'Wed: 10:00am - 6:00pm',
        'Thurs: 10:00am - 6:00pm',
        'Fri: 10:00am - 6:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 11:00am - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/denver/',
      phone: '(720) 586-8602',
      calendly: '',
      location: '121 Clayton Lane Denver, CO 80206',
      locationUrl: 'https://goo.gl/maps/HpB64hKXj2EbKnu46',
    },
  },
  '/showrooms/cs-sales/': {
    slug: '',
    showroom: {
      name: 'CS Sales',
      hours: [],
      url: '',
      phone: '',
      calendly: '',
      location: '',
      locationUrl: '',
    },
  },
  '/showrooms/virtual/': {
    slug: 'virtual-store',
    showroom: {
      name: 'Virtual Showroom',
      hours: [
        'Mon: 7:00am - 7:00pm PT',
        'Tues: 7:00am - 7:00pm PT',
        'Wed: 7:00am - 7:00pm PT',
        'Thurs: 7:00am - 7:00pm PT',
        'Fri: 7:00am - 7:00pm PT',
        'Sat: 7:00am - 7:00pm PT',
        'Sun: 7:00am - 7:00pm PT',
      ],
      url: 'https://joybird.com/showrooms/virtual/',
      phone: '',
      calendly: '',
      location: '',
      locationUrl: '',
    },
  },
  '/showrooms/portland/': {
    slug: 'portland-showroom',
    showroom: {
      name: 'Portland Showroom',
      hours: [
        'Mon: 10:00am - 6:00pm',
        'Tues: 10:00am - 6:00pm',
        'Wed: 10:00am - 6:00pm',
        'Thurs: 10:00am - 6:00pm',
        'Fri: 10:00am - 6:00pm',
        'Sat: 10:00am - 6:00pm',
        'Sun: 12:00pm - 6:00pm',
      ],
      url: 'https://joybird.com/showrooms/portland/',
      phone: '(503) 966-6237',
      calendly: '',
      location: '300 NW 13th Ave. Portland, OR 97209',
      locationUrl: 'https://maps.app.goo.gl/NbmgaZYk59i7Tsaz6',
    },
  },
  '/showrooms/nfmdallas/': { slug: 'nfmdallas-store' },
  '/showrooms/nfmkansascity/': { slug: 'nfmkansascity-store' },
};
