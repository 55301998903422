import isClient from 'commons/isClient';

let isCallbackSet = false;

const refreshFinancingContent = () => {
  if (!isCallbackSet && isClient() && window?.affirm?.ui?.ready) {
    isCallbackSet = true;

    window.affirm.ui.ready(() => {
      window.affirm.ui.refresh();
    });
  }
};

export default refreshFinancingContent;
